import React from 'react'
import { Link } from 'gatsby'
import { useNavigationItem } from 'utils/navigation'

const ContentInfoCard = ({
  heading,
  text,
  buttonPrimaryLink,
  buttonPrimaryText,
  buttonSecondaryLink,
  buttonSecondaryText,
}) => {
  const buttonPrimaryNavLink = useNavigationItem(buttonPrimaryLink)
  const buttonSecondaryNavLink = useNavigationItem(buttonSecondaryLink)
  return (
    <div className="md:flex bg-white md:shadow-lg">
      <div className="md:w-4/12 md:pt-16 md:px-8 lg:px-10 xl:px-16">
        {heading && (
          <>
            <h3 className="text-xl md:text-2xl xl:text-3xl font-semibold leading-tight mb-8 md:mb-14">
              {heading}
            </h3>
            <div className="dash mb-5 md:mb-0"></div>
          </>
        )}
      </div>
      <div className="md:w-8/12 text-xs md:text-lg">
        {text && (
          <div className="md:w-10/12 mb-7 md:mb-0 md:py-16">
            {text && (
              <div
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              ></div>
            )}
          </div>
        )}
        <div className="flex">
          {buttonPrimaryLink && (
            <Link
              className="button-primary px-10 mr-1"
              to={`${buttonPrimaryNavLink}/`}
            >
              {buttonPrimaryText}
            </Link>
          )}
          {buttonSecondaryLink && (
            <Link
              className="button-secondary px-10"
              to={`${buttonSecondaryNavLink}/`}
            >
              {buttonSecondaryText}
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default ContentInfoCard
