import React from 'react'

const ContentHeading = ({ index, heading }) => (
  <section
    className={`my-6 md:my-8 lg:my-10 xl:my-12 ${
      !index ? 'mt-0 md:mt-0 lg:mt-0 xl:mt-0 pt-10 md:pt-22' : ''
    }`}
  >
    {heading && (
      <div className="container">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-xl md:text-2xl lg:text-4xl font-semibold leading-tight mb-6 md:mb-8 lg:mb-10">
            {heading}
          </h2>
          <div className="dash mb-6 md:mb-8 lg:mb-10"></div>
        </div>
      </div>
    )}
  </section>
)

export default ContentHeading
