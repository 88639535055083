import React, { useState } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { useNavigationItem } from 'utils/navigation'

import { useBreakpoint } from '../../utils/breakpoints'

import ContentInfoCard from '../../components/Content/ContentInfoCard'

export default ({
  heading,
  features,
  buttonPrimaryLink,
  buttonPrimaryText,
  buttonSecondaryLink,
  buttonSecondaryText,
}) => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "bg-mountains-a.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const breakpoints = useBreakpoint()

      const [activeFeature, setActiveFeature] = useState(0)
      const [featureName, setFeatureName] = useState(
        (features.length && features[0].feature_name) || ``
      )
      const [featureDescription, setFeatureDescription] = useState(
        (features.length && features[0].feature_description) || ``
      )

      const setFeature = (index, name, description) => {
        setActiveFeature(index)
        setFeatureName(name)
        setFeatureDescription(description)
      }

      /**
       * Returns whether the current index is in the middle of the passed array
       * @author Josh Smith <josh@batch.nz>
       * @param  Array    arr     Array
       * @param  Number   index   Current loop index
       * @return boolean
       */
      const getIsMidpoint = (arr = [], index = 0) => {
        const midpoint = Math.ceil(arr.length / 2)
        const pos = index + 1
        return midpoint === pos
      }
      const buttonPrimaryNavLink = useNavigationItem(buttonPrimaryLink)
      const buttonSecondaryNavLink = useNavigationItem(buttonSecondaryLink)
      return (
        <div className="relative py-8 sm:py-12 lg:pt-16 lg:pb-22">
          <div className="absolute inset-0 z-10">
            <Img
              className="w-full h-full"
              fluid={data.image.childImageSharp.fluid}
              alt={heading || ''}
            />
          </div>
          <div className="relative container z-20">
            {heading && (
              <h3 className="text-white font-semibold text-xl sm:text-2xl lg:text-4xl mb-10 lg:mb-14 leading-none">
                {heading}
              </h3>
            )}
            <div className="flex flex-wrap -ml-8">
              {features && (
                <div className="w-full lg:w-1/4 xl:w-1/3 pl-8 mb-8 lg:mb-0">
                  <div className="flex flex-wrap -ml-4 -mb-10">
                    {features.map(
                      (item, index) =>
                        item.feature_name && (
                          <React.Fragment key={index}>
                            <div className="w-1/3 lg:w-1/2 xl:w-1/3 pl-4 pb-10">
                              <div className="flex flex-col items-center">
                                <button
                                  className={`w-16 h-16 flex items-center justify-center rounded-full shadow-md mb-5 focus:outline-none hover:bg-blue hover:text-white transition-all ${
                                    activeFeature === index
                                      ? 'bg-blue text-white'
                                      : 'bg-white text-blue'
                                  }`}
                                  onClick={() =>
                                    setFeature(
                                      index,
                                      item.feature_name,
                                      item.feature_description
                                    )
                                  }
                                >
                                  {item.feature_icon === 'Lighting' && (
                                    <svg
                                      className="w-10 h-10 fill-current"
                                      viewBox="0 0 25 41"
                                    >
                                      <path d="M18.09,30H7.91A1.74,1.74,0,0,0,6,31.5,1.74,1.74,0,0,0,7.91,33H18.09A1.74,1.74,0,0,0,20,31.5,1.74,1.74,0,0,0,18.09,30ZM20,35.5A1.74,1.74,0,0,0,18.09,34H7.91A1.74,1.74,0,0,0,6,35.5,1.74,1.74,0,0,0,7.91,37H18.09A1.74,1.74,0,0,0,20,35.5ZM8.89,38A1.73,1.73,0,0,0,7,39.5,1.73,1.73,0,0,0,8.89,41h7.22A1.73,1.73,0,0,0,18,39.5,1.73,1.73,0,0,0,16.11,38ZM20,27.32a2.6,2.6,0,0,0,.25-1.08,2,2,0,0,1,0-.26,16.17,16.17,0,0,1,2.47-6.54A12.72,12.72,0,0,0,25,12.2,12.37,12.37,0,0,0,12.5,0,12.37,12.37,0,0,0,0,12.2a12.72,12.72,0,0,0,2.23,7.24A16.07,16.07,0,0,1,4.78,26.6,1.43,1.43,0,0,0,6.23,28H18.77A1.46,1.46,0,0,0,20,27.32Zm-9.59-2.53c1.06-6.35,2.64-8.76,3.1-9.35.21-.26.19-.44.14-.54a.33.33,0,0,0-.3-.17.75.75,0,0,0-.29.08,5.78,5.78,0,0,0-3.16,5.12h0C9.3,19,8,16.47,9,14c.94-2.27,3.59-4,7.86-5.06H17a.33.33,0,0,1,.33.32c.2,1.8.81,7.27-1.39,9.82a3.74,3.74,0,0,1-2.72,1.29.89.89,0,0,0-.87.86v3.5a.44.44,0,0,1-.44.44H10.77a.36.36,0,0,1-.28-.11A.32.32,0,0,1,10.42,24.79Z" />
                                    </svg>
                                  )}

                                  {item.feature_icon === 'Mountains' && (
                                    <svg
                                      className="w-10 h-10 fill-current"
                                      viewBox="0 0 46.42 34.85"
                                    >
                                      <path d="M31.22,6,26.37,15.2,18.36,0,0,34.85H46.42Zm0,5.94,3.61,6.84-.15.09a.91.91,0,0,1-.66.23.92.92,0,0,1-.66-.23,3.8,3.8,0,0,0-4.28,0,1,1,0,0,1-.66.23l-.48-.92Zm-12.86-6,4.94,9.37a4.45,4.45,0,0,0-.45.27,1.53,1.53,0,0,1-1,.34,1.52,1.52,0,0,1-1-.34,4.39,4.39,0,0,0-5,0,1.53,1.53,0,0,1-1,.34,1.52,1.52,0,0,1-1-.34l-.46-.27ZM4.59,32.08h0l7.54-14.32.26.15a4.14,4.14,0,0,0,2.49.77,4.13,4.13,0,0,0,2.48-.77,1.53,1.53,0,0,1,1-.33,1.51,1.51,0,0,1,1,.33,4.39,4.39,0,0,0,5,0l.26-.15,7.55,14.32Zm30.68,0L29.76,21.63a4.74,4.74,0,0,0,.8-.43,1,1,0,0,1,.66-.23,1,1,0,0,1,.66.23,3.65,3.65,0,0,0,2.14.66,3.61,3.61,0,0,0,2.1-.63l5.72,10.85Z" />
                                    </svg>
                                  )}

                                  {item.feature_icon === 'Check-in' && (
                                    <svg
                                      className="w-8 h-8 fill-current"
                                      viewBox="0 0 33.6 34.09"
                                    >
                                      <path d="M13.25,23.86l2.62,2.65L25.2,17,15.87,7.57l-2.62,2.66,4.86,4.92H0v3.79H18.11ZM29.87,0H3.73A3.77,3.77,0,0,0,0,3.79v7.57H3.73V3.79H29.87V30.3H3.73V22.72H0V30.3a3.78,3.78,0,0,0,3.73,3.79H29.87A3.78,3.78,0,0,0,33.6,30.3V3.79A3.77,3.77,0,0,0,29.87,0Z" />
                                    </svg>
                                  )}

                                  {item.feature_icon === 'Temperature' && (
                                    <svg
                                      className="w-10 h-10 fill-current"
                                      viewBox="0 0 20 40"
                                    >
                                      <path d="M16.67,22.55V6.67a6.67,6.67,0,0,0-13.34,0V22.55a10,10,0,1,0,13.34,0ZM10,36.67A6.66,6.66,0,0,1,5.56,25l1.11-1V6.67a3.33,3.33,0,1,1,6.66,0V24l1.11,1A6.66,6.66,0,0,1,10,36.67ZM8.33,13.33h3.34v20H8.33ZM10,25a5,5,0,1,1-5,5A5,5,0,0,1,10,25Z" />
                                    </svg>
                                  )}

                                  {item.feature_icon === 'Wi-Fi' && (
                                    <svg
                                      className="w-9 h-9 fill-current"
                                      viewBox="0 0 32.14 24.19"
                                    >
                                      <path d="M16.14,16.68a3.76,3.76,0,1,1-3.75,3.75A3.75,3.75,0,0,1,16.14,16.68Zm9-5.29L25,11.22l-.17-.16-.1-.07a12.25,12.25,0,0,0-16.92.39l-.54.55-.75.75A2.35,2.35,0,0,0,9.8,16l1.3-1.29a7.55,7.55,0,0,1,10.68,0l.94.9a2.33,2.33,0,0,0,3.3,0A2.28,2.28,0,0,0,26.7,14,2.23,2.23,0,0,0,26,12.32Zm6.39-4.65L30.73,6h0l-.61-.61a2.26,2.26,0,0,0-.35-.28,20.44,20.44,0,0,0-27.61.63L2,5.79.62,7.2a2.15,2.15,0,0,0,3,3L5.07,8.83l0,0a16.13,16.13,0,0,1,22.27-.12l1.1,1.11a2.15,2.15,0,0,0,3-3Z" />
                                    </svg>
                                  )}

                                  {item.feature_icon === 'Chromecast' && (
                                    <svg
                                      className="w-9 h-9 fill-current"
                                      viewBox="0 0 36.66 29.98"
                                    >
                                      <path d="M14.93,30A14.93,14.93,0,0,0,0,15.05V11.57A18.41,18.41,0,0,1,18.41,30ZM8.39,30A8.39,8.39,0,0,0,0,21.6V18.34A11.64,11.64,0,0,1,11.64,30ZM5.06,30H3.81A20.89,20.89,0,0,1,0,25v-.06A5.07,5.07,0,0,1,5.06,30ZM36.66,3.37V25a21.15,21.15,0,0,1-3.81,5H21.63V26.64H33.32V3.34h-30v5H0V3.37A3.37,3.37,0,0,1,3.37,0H33.29A3.37,3.37,0,0,1,36.66,3.37Z" />
                                    </svg>
                                  )}
                                </button>
                                {item.feature_name && (
                                  <h4 className="text-xs sm:text-base text-white text-center leading-tight">
                                    {item.feature_name}
                                  </h4>
                                )}
                              </div>
                            </div>
                            {getIsMidpoint(features, index) &&
                            !breakpoints.lg && ( // Show the content card at the midpoint
                                <div className="w-full pl-5 mb-10">
                                  <div className="bg-white shadow-lg">
                                    <div className="pt-5 px-5">
                                      {featureName && (
                                        <>
                                          <h3 className="text-xl sm:text-2xl font-semibold mb-6">
                                            {featureName}
                                          </h3>
                                          <div className="dash mb-7"></div>
                                        </>
                                      )}
                                      {featureDescription.html && (
                                        <div
                                          className="text-xs sm:text-base mb-7"
                                          dangerouslySetInnerHTML={{
                                            __html: featureDescription.html,
                                          }}
                                        ></div>
                                      )}
                                    </div>
                                    <div className="sm:pb-5 sm:px-5">
                                      {buttonPrimaryLink && (
                                        <Link
                                          className="w-full sm:w-auto button-primary px-10 mb-px sm:mb-0 sm:mr-1"
                                          to={buttonPrimaryNavLink}
                                        >
                                          {buttonPrimaryText}
                                        </Link>
                                      )}
                                      {buttonSecondaryLink && (
                                        <Link
                                          className="w-full sm:w-auto button-primary px-10"
                                          to={buttonSecondaryNavLink}
                                        >
                                          {buttonSecondaryText}
                                        </Link>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                          </React.Fragment>
                        )
                    )}
                  </div>
                </div>
              )}
              {breakpoints.lg && (
                <div className="w-3/4 xl:w-2/3 pl-8">
                  <ContentInfoCard
                    heading={featureName}
                    text={featureDescription.html}
                    buttonPrimaryLink={buttonPrimaryLink}
                    buttonPrimaryText={buttonPrimaryText}
                    buttonSecondaryLink={buttonSecondaryLink}
                    buttonSecondaryText={buttonSecondaryText}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )
    }}
  />
)
